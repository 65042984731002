.container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-image: url("https://img.freepik.com/vetores-gratis/bandeira-de-conceitos-de-palavra-front-end-programacao-de-aplicativos-da-web_106317-84.jpg?size=626&ext=jpg");
    background-position: center;
    background-size: contain;
    border: black 5px solid;
    width: 70%;
    margin: 100px auto;
    box-shadow:  0.2rem 0.3rem 2px rgba(0, 0, 0, 0.6);
    animation: move 120s;
}
.cover{
    width: 20%;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 10px 5px 5px black;
    text-align: center;
}
@keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -2212px 0;
    }
  }
.container:hover{
    background-color: bisque;
}